import {
  OrderTypes,
  PaymentMethodTypes,
  swipeCardTitles
} from "main/pos/constant/PosConstant"
import {
  extractActivePaymentMethod,
  isPaymentMethodActive
} from "main/pos/helpers/PosHelpers"
import { swipeCardTitlesTypes } from "main/pos/payment/PaymentTypes"

const getPaymentMethodStatus = (
  basicPaymentData: [],
  orderType: string,
  paymentType: string,
  orderList: Array<string>
) => {
  return (
    basicPaymentData?.find(
      (merchantPaymentMode: any) =>
        merchantPaymentMode.name === paymentType &&
        merchantPaymentMode.availableOn.includes("Pos")
    ) && orderList.includes(orderType)
  )
}

export const getPaymentMethodConstant = (
  orderType: string,
  merchantPayment: [],
  basicPaymentData: [],
  isDigitalProduct: boolean,
  isInternetOn: boolean
) => {
  const paymentMethods = [
    {
      title: "Cash On Delivery",
      value: PaymentMethodTypes.CashOnDelivery,
      isActive:
        getPaymentMethodStatus(
          basicPaymentData,
          orderType,
          PaymentMethodTypes.CashOnDelivery,
          [OrderTypes.Delivery]
        ) && !isDigitalProduct
    },
    {
      title: orderType === OrderTypes.WalkIn ? "Cash" : "Cash On Pickup",
      value: PaymentMethodTypes.Cash,
      isActive:
        (orderType === OrderTypes.PickUp || orderType === OrderTypes.WalkIn) &&
        !isDigitalProduct
    },
    {
      title: swipeCardTitles[orderType as keyof swipeCardTitlesTypes],
      value: PaymentMethodTypes.SwipeCard,
      isActive:
        getPaymentMethodStatus(
          basicPaymentData,
          orderType,
          PaymentMethodTypes.SwipeCard,
          [OrderTypes.Delivery, OrderTypes.PickUp, OrderTypes.WalkIn]
        ) && !isDigitalProduct
    },
    {
      title: "Swipe with machine",
      value: PaymentMethodTypes.SwipeCardWithMachine,
      isActive:
        getPaymentMethodStatus(
          basicPaymentData,
          orderType,
          PaymentMethodTypes.SwipeCard,
          [OrderTypes.Delivery, OrderTypes.PickUp, OrderTypes.WalkIn]
        ) && !isDigitalProduct
    },
    {
      title: "Prepaid",
      value: PaymentMethodTypes.Prepaid,
      isActive: true
    },
    {
      title: "Online Payment",
      value: PaymentMethodTypes.OnlinePayment,
      isActive: Boolean(extractActivePaymentMethod(merchantPayment)),
      disabled: !isInternetOn
    },
    {
      title: "Credit",
      value: PaymentMethodTypes.Credit,
      isActive: orderType === OrderTypes.WalkIn
    },
    {
      title: "Zindigi QR",
      value: PaymentMethodTypes.ZindigiQR,
      isActive: Boolean(
        isPaymentMethodActive(merchantPayment, PaymentMethodTypes.ZindigiQR)
      )
    }
  ]
  return paymentMethods
}
