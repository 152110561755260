import { useContext, useEffect, useState, useRef } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import pulseClient from "graphql/pulse"
import phoenixClient from "graphql/phoenix"
import nebulaClient from "graphql/nebula"
import spaceBridgeClient from "graphql/SpaceBridge"
import { CREATE_ORDER } from "main/pos/checkout/CheckoutApi"
import {
  GET_MERCHANT_CUSTOMERS,
  GET_CATEGORIES,
  PRODUCTS,
  GET_CITIES,
  GET_AREAS,
  DELIVERY_CONFIG,
  MERCHANT_PAYMENT_PROVIDER,
  GET_MERCHANT_PAYMENTS_MODE,
  MERCHANT_PROFILE,
  GET_MASTER_PROFILE,
  ALL_AREAS,
  GET_POS_CONFIG,
  GET_MERCHANT_INTEGRATIONS,
  BIN_DISCOUNTS_BY_MERCHANT,
  GET_PARTNERSHIP,
  GET_TAX_CLASSES,
  GET_SERVER
} from "main/resolvers/Resolvers"
import {
  addBulkData,
  addBulkDataWithSearch,
  addData,
  fetchDataWithSearch,
  deleteData,
  deleteSingleData,
  modifyData
} from "offline_database/queries/DbOperations"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import {
  getBranchVariable,
  convertProductImages
} from "main/pos/helpers/SyncHelpers"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { BvidsNoImages } from "constant/BvidsNoImageConstant"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { useDispatch } from "react-redux"
import { setBackgroundSync } from "main/context/connection/ConnectionSlice"

export const useSync = (backgroundSync?: boolean) => {
  const { getBranchBvid, selectedBranch } = useContext(
    BranchContext
  ) as BranchContextType

  const { getUserMerchantBvid, getUserMasterBvid } = useContext(
    UserContext
  ) as UserContextType

  const {
    setMerchantIntegrations,
    setMerchantBinDiscounts,
    setPartnerships,
    setTaxClasses
  } = useContext(PosContext) as PosContextType
  const dispatch = useDispatch()

  const { setIsProductFetched } = useContext(
    PosProductContext
  ) as PosProductType

  const [resolverName, setResolverName] = useState<any>([])
  const [productLoading, setProductLoading] = useState(true)
  const [orderCreateLoading, setOrderCreateLoading] = useState(true)
  const fetchQuery = useRef(true)
  const branchBvid = getBranchBvid()
  const merchantBvid = getUserMerchantBvid()
  const masterBvid = getUserMasterBvid()
  const addDataAfterComplete = (
    data: any,
    title: string,
    addData: (_tableName: string, _data: any, _searchFields?: any) => void,
    databaseName: string,
    query?: object[]
  ) => {
    deleteData(databaseName)
    if (query) addData(databaseName, data, query)
    else {
      addData(databaseName, data)
    }
    addQueryAndStatus(title, true)
  }
  const [fetchPosConfigs, { loading: posConfigLoading }] = useLazyQuery(
    GET_POS_CONFIG,
    {
      client: pulseClient,
      onCompleted: (data) =>
        addDataAfterComplete(
          data?.posConfig,
          "Pos Configs",
          addData,
          "posConfigs"
        ),
      onError: () => addQueryAndStatus("Pos Configs", false)
    }
  )
  const [fetchPartnerships, { loading: partnershipLoading }] = useLazyQuery(
    GET_PARTNERSHIP,
    {
      client: pulseClient,
      onCompleted: (data) => {
        addDataAfterComplete(
          data?.partnerships,
          "Partnerships",
          addBulkData,
          "partnershipList"
        )
        setPartnerships(data?.partnerships)
      },
      onError: () => addQueryAndStatus("Partnership", false)
    }
  )
  const [fetchServers, { loading: serverLoadinng }] = useLazyQuery(GET_SERVER, {
    client: pulseClient,
    onCompleted: (data) => {
      addDataAfterComplete(
        data?.serverUsers,
        "Servers",
        addBulkData,
        "serverList"
      )
    },
    onError: () => addQueryAndStatus("Servers", false)
  })
  const [fetchTaxClassificaion, { loading: taxClassLoading }] = useLazyQuery(
    GET_TAX_CLASSES,
    {
      client: phoenixClient,
      onCompleted: (data) => {
        addDataAfterComplete(
          data?.taxClasses?.taxClasses,
          "Tax Classification",
          addBulkData,
          "taxClassification"
        )
        setTaxClasses(data?.taxClasses?.taxClasses)
      },
      onError: () => addQueryAndStatus("Tax Classification", false)
    }
  )
  const [createOrder] = useMutation(CREATE_ORDER, {
    client: pulseClient,
    variables: {
      ...(branchBvid && { merchantBvid: branchBvid })
    },
    onError: () => addQueryAndStatus("Orders", false)
  })
  const [fetchMerchantPaymentProviders, { loading: merchantPaymentLoading }] =
    useLazyQuery(MERCHANT_PAYMENT_PROVIDER, {
      client: spaceBridgeClient,
      onCompleted: (data) =>
        addDataAfterComplete(
          data?.merchantPaymentProviders,
          "Payment Providers",
          addBulkData,
          "merchantPaymentProviders"
        ),
      onError: () => addQueryAndStatus("Payment Providers", false)
    })
  const [fetchMerchantPaymentModes, { loading: merchantPaymentModesLoading }] =
    useLazyQuery(GET_MERCHANT_PAYMENTS_MODE, {
      client: pulseClient,
      onCompleted: (data) => {
        const paymentModes = data?.merchantPaymentModes?.map(
          (
            paymentMode: {
              mode: string
              availableOn: string[]
            },
            index: number
          ) => ({
            id: index,
            name: paymentMode.mode,
            availableOn: paymentMode.availableOn
              ? [...paymentMode.availableOn]
              : []
          })
        )
        addDataAfterComplete(
          paymentModes,
          "Payment Modes",
          addBulkData,
          "merchantPaymentModes"
        )
      },
      onError: () => addQueryAndStatus("Payment Modes", false)
    })

  const [fetchMerchantProfile, { loading: merchantProfileLoading }] =
    useLazyQuery(MERCHANT_PROFILE, {
      client: pulseClient,
      onCompleted: (data) =>
        addDataAfterComplete(
          data?.merchantProfile,
          "Profile",
          addData,
          "merchantProfile"
        ),
      onError: () => addQueryAndStatus("Profile", false)
    })

  const [fetchMasterProfile, { loading: masterProfileLoading }] = useLazyQuery(
    GET_MASTER_PROFILE,
    {
      client: pulseClient,
      onCompleted: (data) =>
        addDataAfterComplete(
          data?.getMasterProfile,
          "Master",
          addData,
          "masterProfile"
        ),
      onError: () => addQueryAndStatus("Master", false)
    }
  )

  const [merchantCategory, { loading: merchantCategoryLoading }] = useLazyQuery(
    GET_CATEGORIES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) =>
        addDataAfterComplete(
          data?.merchantCategories,
          "Categories",
          addBulkData,
          "categoryList"
        ),
      onError: () => addQueryAndStatus("Categories", false)
    }
  )

  const [merchantProducts] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "network-only",
    onError: () => addQueryAndStatus("Products", false)
  })

  const [fetchCities, { loading: citiesLoading }] = useLazyQuery(GET_CITIES, {
    client: pulseClient,
    onCompleted: (data) =>
      addDataAfterComplete(
        data?.getCitiesForPos,
        "Cities",
        addBulkData,
        "cityList"
      ),
    onError: () => addQueryAndStatus("Cities", false)
  })

  const [fetchAreas, { loading: areasLoading }] = useLazyQuery(GET_AREAS, {
    client: pulseClient,
    onCompleted: (data) =>
      addDataAfterComplete(
        data?.getAreasForPos,
        "Areas",
        addBulkDataWithSearch,
        "areaList",
        [{ parentKey: "city", key: "bvid" }]
      ),
    onError: () => addQueryAndStatus("Areas", false)
  })

  const [fetchAllAreas, { loading: allAreasLoading }] = useLazyQuery(
    ALL_AREAS,
    {
      client: pulseClient,
      onError: () => addQueryAndStatus("Areas", false)
    }
  )
  const [fetchMerchantCustomers, { loading: merchantCustomersLoading }] =
    useLazyQuery(GET_MERCHANT_CUSTOMERS, {
      client: pulseClient,
      onError: () => addQueryAndStatus("Customers", false)
    })
  const createOrders = async () => {
    setOrderCreateLoading(true)
    const list = await fetchDataWithSearch("orderList")
    if (list?.length) {
      list.forEach(async (order: any) => {
        const orderResponse = await createOrder({ variables: order?.data })
        if (orderResponse?.data) {
          order?.data?.products?.forEach((product: any) => {
            checkAndModifyProduct(product)
          })
          await deleteSingleData("orderList", "orderNumber", order?.orderNumber)
        }
      })
      addQueryAndStatus("Orders", true)
    }

    setOrderCreateLoading(false)
  }

  const [getMerchantIntegrations] = useLazyQuery(GET_MERCHANT_INTEGRATIONS, {
    client: nebulaClient,
    onCompleted: (data) => {
      addDataAfterComplete(
        data?.merchantIntegrations,
        "Merchant Integrations",
        addBulkData,
        "merchantIntegrations"
      ),
        setMerchantIntegrations(data.merchantIntegrations)
    }
  })

  const [getBinDiscountByMerchant] = useLazyQuery(BIN_DISCOUNTS_BY_MERCHANT, {
    client: spaceBridgeClient,
    onCompleted: (data) => {
      addDataAfterComplete(
        data?.binDiscountsForMerchantXid,
        "Merchant Bin Discounts",
        addBulkData,
        "merchantBinDiscounts"
      ),
        setMerchantBinDiscounts(data?.binDiscountsForMerchantXid)
    }
  })

  const checkAndModifyProduct = async (product: any) => {
    const singleProduct = await fetchDataWithSearch(
      "productList",
      "bvid",
      product.xid
    )
    const extractProduct = singleProduct[0]

    const { quantity } = extractProduct?.data ?? {}
    const newQuantity = quantity - product?.quantity
    extractProduct.data.quantity = newQuantity

    modifyData(
      "productList",
      "bvid",
      extractProduct.data.bvid,
      extractProduct.data
    )
  }

  const [fetchDeliveryConfig, { loading: deliveryConfigLoading }] =
    useLazyQuery(DELIVERY_CONFIG, {
      client: pulseClient,
      fetchPolicy: "network-only",
      onCompleted: (data) =>
        addDataAfterComplete(
          data?.deliveryConfigs,
          "Delivery Configs",
          addBulkData,
          "deliveryConfigsList"
        ),
      onError: () => addQueryAndStatus("Delivery Configs", false)
    })

  const addQueryAndStatus = (resolverName: string, resolverStatus: boolean) => {
    setResolverName((prev: any = []) => {
      const checkExistingResolver = prev?.some(
        (resolver: { name: string; status: boolean }) =>
          resolver.name === resolverName
      )
      if (!checkExistingResolver)
        return [...prev, { name: resolverName, status: resolverStatus }]
      else return [...prev]
    })
  }
  const syncLoading =
    orderCreateLoading ||
    merchantPaymentLoading ||
    merchantPaymentModesLoading ||
    merchantProfileLoading ||
    masterProfileLoading ||
    merchantCategoryLoading ||
    productLoading ||
    citiesLoading ||
    areasLoading ||
    merchantCustomersLoading ||
    deliveryConfigLoading ||
    allAreasLoading ||
    posConfigLoading ||
    partnershipLoading ||
    taxClassLoading ||
    serverLoadinng

  const fetchAllProducts = async () => {
    const merchantBvid = getUserMerchantBvid()

    try {
      const merchantProductResponse: any = await merchantProducts({
        variables: {
          size: 50,
          offset: 0,
          productStatus: "Active",
          ...(branchBvid && { merchantXid: branchBvid })
        }
      })

      if (merchantProductResponse?.data) {
        const defaultTotal = 50
        const { totalCount } =
          merchantProductResponse?.data?.merchantProductsForPos ?? {}

        deleteData("productList")

        if (defaultTotal > totalCount) {
          let allProduct: any =
            merchantProductResponse?.data?.merchantProductsForPos?.products ??
            []

          if (!BvidsNoImages?.includes(branchBvid || merchantBvid))
            allProduct = await convertProductImages(allProduct)

          addBulkDataWithSearch("productList", allProduct || [], [
            "name",
            "description",
            "categoryBvid",
            "subcategoryBvid",
            "barcode",
            "bvid"
          ])
          setIsProductFetched(true)
        } else {
          let collectedProducts: any =
            merchantProductResponse?.data?.merchantProductsForPos?.products ??
            []

          if (!BvidsNoImages?.includes(branchBvid || merchantBvid))
            collectedProducts = await convertProductImages(collectedProducts)

          const allProducts: any = []
          allProducts.push(...(collectedProducts ?? []))
          const apiCycleLimit = Math.ceil(totalCount / defaultTotal)

          for (let apiCycle = 1; apiCycle <= apiCycleLimit; apiCycle++) {
            const merchantProductInner: any = await merchantProducts({
              variables: {
                size: 50,
                offset: defaultTotal * apiCycle,
                productStatus: "Active",
                ...(branchBvid && { merchantXid: branchBvid })
              }
            })
            if (
              merchantProductInner?.data?.merchantProductsForPos?.products
                ?.length
            ) {
              let collectedProducts: any =
                merchantProductInner?.data?.merchantProductsForPos?.products ??
                []

              if (!BvidsNoImages?.includes(branchBvid || merchantBvid))
                collectedProducts =
                  await convertProductImages(collectedProducts)

              allProducts.push(...(collectedProducts ?? []))
            } else break
          }
          addBulkDataWithSearch("productList", allProducts, [
            "name",
            "description",
            "categoryBvid",
            "subcategoryBvid",
            "barcode",
            "bvid"
          ])
          setIsProductFetched(true)
        }
        addQueryAndStatus("Products", true)
      }
    } catch (err) {
      console.info(err)
    }
    setProductLoading(false)
  }

  const fetchAllCustomers = async () => {
    const merchantCustomersResponse: any = await fetchMerchantCustomers({
      variables: {
        size: 50,
        offset: 0,
        ...(branchBvid && { merchantBvid: branchBvid })
      }
    })

    if (merchantCustomersResponse?.data) {
      const defaultTotal = 50
      const { count } =
        merchantCustomersResponse?.data?.getMerchantCustomersWithAddress ?? {}

      deleteData("merchantCustomersList")

      if (defaultTotal > count) {
        addBulkDataWithSearch(
          "merchantCustomersList",
          merchantCustomersResponse.data?.getMerchantCustomersWithAddress
            ?.customers,
          ["username"]
        )
      } else {
        const allCustomers: any = []
        allCustomers.push(
          ...(merchantCustomersResponse?.data?.getMerchantCustomersWithAddress
            ?.customers ?? [])
        )
        const apiCycleLimit = Math.ceil(count / defaultTotal)

        for (let apiCycle = 1; apiCycle <= apiCycleLimit; apiCycle++) {
          const merchantCustomerInner: any = await fetchMerchantCustomers({
            variables: {
              size: 50,
              offset: defaultTotal * apiCycle,
              ...(branchBvid && { merchantBvid: branchBvid })
            }
          })
          if (
            merchantCustomerInner?.data?.getMerchantCustomersWithAddress
              ?.customers?.length
          ) {
            allCustomers.push(
              ...(merchantCustomerInner?.data?.getMerchantCustomersWithAddress
                ?.customers ?? [])
            )
          } else break
        }
        addBulkDataWithSearch("merchantCustomersList", allCustomers, [
          "username"
        ])
      }
    }
  }

  const fetchAllMerchantAreas = async () => {
    const areasResponse = await fetchAreas({
      variables: { ...(branchBvid && { merchantBvid: branchBvid }) }
    })
    if (!areasResponse?.data?.getAreasForPos?.length) {
      const allAreasResponse: any = await fetchAllAreas({
        variables: {
          size: 500,
          offset: 0
        }
      })

      if (allAreasResponse?.data) {
        const defaultTotal = 500
        const { count } = allAreasResponse?.data?.allAreasForPos ?? {}
        deleteData("areaList")

        if (defaultTotal > count) {
          addBulkDataWithSearch(
            "areaList",
            allAreasResponse.data?.allAreasForPos?.areas,
            [{ parentKey: "city", key: "bvid" }]
          )
        } else {
          const allAreas: any = []
          allAreas.push(
            ...(allAreasResponse?.data?.allAreasForPos?.areas ?? [])
          )
          const apiCycleLimit = Math.ceil(count / defaultTotal)
          for (let apiCycle = 1; apiCycle <= apiCycleLimit; apiCycle++) {
            const allAreasInner: any = await fetchAllAreas({
              variables: {
                size: 500,
                offset: defaultTotal * apiCycle
              }
            })
            if (allAreasInner?.data?.allAreasForPos?.areas?.length) {
              allAreas.push(
                ...(allAreasInner?.data?.allAreasForPos?.areas ?? [])
              )
            } else break
          }
          addBulkDataWithSearch("areaList", allAreas, [
            { parentKey: "city", key: "bvid" }
          ])
        }
        addQueryAndStatus("Areas", true)
      }
    }
  }

  const fetchQueries = async () => {
    if (fetchQuery.current) {
      fetchPosConfigs(getBranchVariable(branchBvid, "merchantBvid"))
      fetchDeliveryConfig(getBranchVariable(branchBvid, "merchantBvid"))
      fetchMerchantPaymentProviders(
        getBranchVariable(branchBvid, "merchantXid")
      )
      fetchMerchantPaymentModes(getBranchVariable(branchBvid, "merchantBvid"))
      fetchMerchantProfile(getBranchVariable(branchBvid, "bvid"))
      fetchMasterProfile({
        variables: { masterBvid: masterBvid }
      })
      fetchCities(getBranchVariable(branchBvid, "merchantBvid"))
      fetchAllMerchantAreas()
      getMerchantIntegrations({
        variables: { merchantXid: merchantBvid || selectedBranch }
      })
      getBinDiscountByMerchant({
        variables: { merchantXid: merchantBvid || selectedBranch }
      })
      merchantCategory({
        variables: {
          status: "Active",
          ...(branchBvid && { merchantXid: branchBvid })
        }
      })
      fetchPartnerships()
      fetchServers({
        variables: { merchantBvid: merchantBvid || selectedBranch }
      })
      fetchTaxClassificaion({
        variables: {
          ...(masterBvid ? { masterXid: masterBvid } : {}),
          ...(merchantBvid || branchBvid
            ? { merchantXid: merchantBvid || branchBvid }
            : {})
        }
      })
      fetchAllProducts()
      createOrders()
    }
    fetchQuery.current = false
  }

  const fetchHiddenQueries = () => {
    if (fetchQuery.current) {
      fetchAllCustomers()
      createOrders()
    }
    dispatch(setBackgroundSync(false))
    fetchQuery.current = false
  }
  useEffect(() => {
    fetchAllCustomers()
    if (backgroundSync) fetchHiddenQueries()
    else fetchQueries()
  }, [])
  return { resolverName, loading: syncLoading }
}
