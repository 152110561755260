import Dexie, { Table } from "dexie"
import {
  CommonTypes,
  ProductTypes,
  AreasSubAreasTypes,
  merchantCustomersTypes,
  orderTypes
} from "offline_database/DbTypes"
export class MySubClassedDexie extends Dexie {
  categoryList!: Table<CommonTypes>
  productList!: Table<ProductTypes>
  orderList!: Table<orderTypes>
  draftOrderList!: Table<orderTypes>
  cityList!: Table<CommonTypes>
  areaList!: Table<AreasSubAreasTypes>
  deliveryConfigsList!: Table<CommonTypes>
  merchantPaymentProviders!: Table<CommonTypes>
  merchantPaymentModes!: Table<CommonTypes>
  merchantProfile!: Table<CommonTypes>
  masterProfile!: Table<CommonTypes>
  merchantCustomersList!: Table<merchantCustomersTypes>
  posConfigs!: Table<CommonTypes>
  merchantIntegrations!: Table<CommonTypes>
  partnershipList!: Table<CommonTypes>
  serverList!: Table<CommonTypes>
  taxClassification!: Table<CommonTypes>

  constructor() {
    super("Pos")
    this.version(1).stores({
      categoryList: "++id",
      productList:
        "++id,name,description,categoryBvid,subcategoryBvid,data,barcode,bvid",
      orderList: "++id,orderNumber",
      cityList: "++id",
      areaList: "++id,bvid",
      deliveryConfigsList: "++id",
      merchantPaymentProviders: "++id",
      merchantProfile: "++id",
      masterProfile: "++id",
      merchantCustomersList: "++id,username"
    })
    this.version(2).stores({
      posConfigs: "++id",
      merchantPaymentModes: "++id",
      merchantIntegrations: "++id",
      partnershipList: "++id",
      serverList: "++id",
      taxClassification: "++bvid"
    })
    this.version(3).stores({
      draftOrderList: "++id,orderNumber"
    })
  }
}

export const db = new MySubClassedDexie()
