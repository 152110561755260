import { swipeCardTitlesTypes } from "main/pos/payment/PaymentTypes"

export const OrderTypes = Object.freeze({
  Delivery: "Delivery",
  PickUp: "PickUp",
  Table: "Table",
  WalkIn: "WalkIn"
})

export const OrderSourceList = [
  {
    label: "Facebook",
    value: "Facebook"
  },
  {
    label: "Whatsapp",
    value: "Whatsapp"
  },
  {
    label: "Instagram",
    value: "Instagram"
  },
  {
    label: "Web",
    value: "Web"
  },
  {
    label: "Phone Call",
    value: "Phone"
  },
  {
    label: "Others",
    value: "Others"
  },
  {
    label: "WhatsappBot",
    value: "WhatsappBot"
  },
  {
    label: "Foodpanda",
    value: "FoodPandaPOS"
  }
]

export const StoreModes = Object.freeze({
  Restaurant: "Restaurant"
})

export const DiscountTypes = Object.freeze({
  Flat: "Flat",
  Percent: "Percent",
  Voucher: "Voucher",
  StoreFlatDiscount: "StoreFlatDiscount",
  Invoice: "Invoice",
  Category: "CategoryFlat"
})

export const discountTypeList = [
  {
    title: "Flat",
    value: DiscountTypes.Flat,
    isActive: true
  },
  {
    title: "Percent",
    value: DiscountTypes.Percent,
    isActive: true
  }
]

export const PaymentMethodTypes = Object.freeze({
  CashOnDelivery: "CashOnDelivery",
  Prepaid: "Prepaid",
  OnlinePayment: "OnlinePayment",
  Cash: "Cash",
  SwipeCard: "SwipeCard",
  SwipeCardWithMachine: "SwipeCardWithMachine",
  Credit: "Credit",
  ZindigiQR: "ZindigiQR"
})

export const swipeCardTitles: swipeCardTitlesTypes = {
  Delivery: "Swipe On Delivery",
  PickUp: "Swipe On Pickup",
  WalkIn: "Swipe Card",
  Cash: "Cash"
}
