import React, { useEffect, useState, useContext } from "react"
import { PosProvider } from "main/context/pos/PosContext"
import {
  DiscountTypes,
  OrderTypes,
  PaymentMethodTypes
} from "main/pos/constant/PosConstant"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { IntegrationType, PosValues } from "main/context/pos/PosContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import { DeliveryOptions } from "main/pos/order/constants/OrderConstants"
import { checkPastTime } from "main/util/DateTimeHelper"
import { revenuePartners } from "utils/fbrHelpers"
import { calculateLotTax } from "main/pos/product/product_lots/helpers/ProductLotsHelper"
import { getFromStorage, keys } from "main/util/LocalStorageHelper"
import { useDispatch } from "react-redux"
import { setFBRPosId } from "../connection/ConnectionSlice"
import ConnectionHooks from "../connection/ConnectionHook"

const PosContainer = ({ children }: { children: React.ReactNode }) => {
  const [orderType, setOrderType] = useState(OrderTypes.WalkIn)
  const [checkoutDialog, setCheckoutDialog] = useState(false)
  const [showDraftedOrders, setShowDraftedOrders] = useState(false)
  const [categoryDiscount, setCategoryDiscount] = useState(0)

  const shiftInfo = getFromStorage(keys.SHIFT)
  const dispatch = useDispatch()

  const [discount, setDiscount] = useState(0)
  const [discountType, setDiscountType] = useState(DiscountTypes.Flat)
  const [deliveryCharges, setDeliveryCharges] = useState(0)
  const [merchantProfileData, setMerchantProfileData] = useState({})
  const [masterProfileData, setMasterProfileData] = useState({})
  const [flatDiscountPercentage, setFlatDiscountPercentage] = useState(0)
  const [flatDiscount, setFlatDiscount] = useState(0)
  const [specialDiscount, setSpecialDiscount] = useState(0)
  const [voucherDiscount, setVoucherDiscount] = useState(0)
  const [voucherCode, setVoucherCode] = useState("")
  const [merchantBinDiscounts, setMerchantBinDiscounts] = useState([])
  const [binDiscount, setBinDiscount] = useState(0)
  const [binNumber, setBinNumber] = useState("")
  const [shiftBvid, setShiftBvid] = useState(shiftInfo?.shiftBvid)
  const [categoryVoucherId, setCategoryVoucherId] = useState("")
  const [voucherDetails, setVoucherDetails] = useState({})
  const [orderInstruction, setOrderInstruction] = useState("")
  const [cashReceived, setCashReceived] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [paymentMethodsList, setPaymentMethodsList] = useState([])
  const [deliveryConfigsData, setDeliveryConfigsData] = useState("")
  const [posConfigs, setPosConfigs] = useState({})
  const [tax, setTax] = useState(0)
  const [inclTax, setInclTax] = useState(0)
  const [fbrCharges, setFbrCharges] = useState(0)
  const [fbrInvoiceNumber, setFbrInvoiceNumber] = useState("-")
  const [merchantIntegrations, setMerchantIntegrations] = useState([])
  const [tip, setTip] = useState("")
  const [taxClasses, setTaxClasses] = useState([])
  const [selectedServer, setSelectedServer] = useState("")

  const [deliveryChoice, setDeliveryChoice] = useState(
    DeliveryOptions.DeliveryAsap
  )
  const [partnershipValue, setPartnershipValue] = useState<string>("")
  const [isDeliveryLater, setIsDeliveryLater] = useState(false)
  const [deliveryLaterTiming, setDeliveryLaterTiming] = useState<Date | string>(
    ""
  )
  const [delveryHours, setDeliveryHours] = useState<any>()
  const [deliveryMinutes, setDeliveryMinutes] = useState<any>()
  const [deliveryMeridiem, setDeliveryMeridiem] = useState<any>("")
  const [deliveryDate, setDeliveryDate] = useState("")
  const [isShiftStarted, setIsShiftStarted] = useState(false)
  const [fbrIdsList, setFbrIdsList] = useState<any>([])
  const [fbrDialog, setFbrDialog] = useState(false)
  const [binBvid, setBinBvid] = useState("")
  const [orderIdentifier, setOrderIdentifier] = useState("")
  const [isDraftOrderLoaded, setIsDraftOrderLoaded] = useState(false)
  const [isHoldOrder, setIsHoldOrder] = useState(false)

  const { getBranchBvid } = useContext(BranchContext) as BranchContextType

  const { isInternetOn, firstSync, FBRPosId } = ConnectionHooks()
  const branchBvid = getBranchBvid()
  const isDigitalPayment =
    PaymentMethodTypes.OnlinePayment === paymentMethod ||
    PaymentMethodTypes.SwipeCard === paymentMethod
  const fetchOfflinePosConfigs = async () => {
    const posConfigs = await fetchDataWithSearch("posConfigs")
    setPosConfigs(posConfigs[0])
  }

  const fetchOfflinePaymentMethods = async () => {
    const paymentMethods = await fetchDataWithSearch("merchantPaymentProviders")
    setPaymentMethodsList(paymentMethods)
  }

  const fetchOfflineConfigs = async () => {
    const configData = await fetchDataWithSearch("deliveryConfigsList")
    setDeliveryConfigsData(configData)
  }

  const fetchOfflineMasterProfile = async () => {
    const masterProfile = await fetchDataWithSearch("masterProfile")
    setMasterProfileData(masterProfile[0])
  }

  const fetchOfflineMerchantProfile = async () => {
    const merchantProfile = await fetchDataWithSearch("merchantProfile")
    setMerchantProfileData(merchantProfile[0])
    setFlatDiscountPercentage(merchantProfile[0]?.config?.flatDiscount || 0)
  }

  const saveProductLotTax = (products: Record<string, unknown>) => {
    const tax = calculateLotTax(products)
    setTax(Math.round(tax))
  }

  useEffect(() => {
    fetchOfflinePosConfigs()
    fetchOfflinePaymentMethods()
    fetchOfflineConfigs()
    fetchOfflineMerchantProfile()
    fetchOfflineMasterProfile()
  }, [isInternetOn, branchBvid, firstSync])

  useEffect(() => {
    const isRevenueIntegration = (item: { partner: string }) =>
      item.partner === revenuePartners.Fbr ||
      item.partner === revenuePartners.Pra ||
      item.partner === revenuePartners.Srb

    merchantIntegrations.find((item: IntegrationType) => {
      if (isRevenueIntegration(item) && item.status === "Active") {
        if (
          item.partner === revenuePartners.Fbr ||
          item.partner === revenuePartners.Srb ||
          item.partner === revenuePartners.Pra
        )
          setFbrCharges(1)

        if (item?.xid?.includes(",") && !FBRPosId && firstSync) {
          const idsList = item?.xid?.split(",")
          setFbrIdsList(idsList)
          setFbrDialog(true)
          return false
        } else if (!item?.xid?.includes(",")) {
          dispatch(setFBRPosId(item.xid))
          return true
        }
      }
    })
  }, [merchantIntegrations, firstSync])

  const getIsDeliveryLaterValid = () => {
    if (isDeliveryLater) return !checkPastTime(deliveryLaterTiming)
    return true
  }

  const resetPosValues = () => {
    setDiscount(0)
    setCashReceived("0")
    setPaymentMethod("")
    setVoucherCode("")
    setVoucherDiscount(0)
    setSpecialDiscount(0)
    setOrderInstruction("")
    setDeliveryLaterTiming("")
    setIsDeliveryLater(false)
    setDeliveryChoice(DeliveryOptions.DeliveryAsap)
    setBinBvid("")
    setBinDiscount(0)
    setBinNumber("")
    setOrderIdentifier("")
    setIsDraftOrderLoaded(false)
    setPartnershipValue("")
    setTip("")
    setSelectedServer("")
  }

  const updateState = (data: any, setState: any) => {
    if (data) setState(data)
  }

  const applyPosValues = ({
    discount,
    discountType,
    cashReceived,
    paymentMethod,
    voucherCode,
    voucherDiscount,
    specialDiscount,
    orderInstruction,
    deliveryLaterTiming,
    isDeliveryLater,
    deliveryChoice,
    orderNumber,
    orderType,
    partnershipValue
  }: PosValues) => {
    updateState(cashReceived, setCashReceived)
    updateState(orderType, setOrderType)
    updateState(paymentMethod, setPaymentMethod)
    updateState(voucherCode, setVoucherCode)
    updateState(voucherDiscount, setVoucherDiscount)
    updateState(specialDiscount, setSpecialDiscount)
    updateState(orderInstruction, setOrderInstruction)
    updateState(deliveryLaterTiming, setDeliveryLaterTiming)
    updateState(isDeliveryLater, setIsDeliveryLater)
    updateState(deliveryChoice, setDeliveryChoice)
    updateState(orderNumber, setOrderIdentifier)
    updateState(discountType, setDiscountType)
    updateState(discount, setDiscount)
    updateState(partnershipValue, setPartnershipValue)

    setIsDraftOrderLoaded(true)
  }

  return (
    <PosProvider
      value={{
        orderType,
        setOrderType,
        discount,
        setDiscount,
        deliveryCharges,
        setDeliveryCharges,
        merchantProfileData,
        masterProfileData,
        orderInstruction,
        setOrderInstruction,
        cashReceived,
        setCashReceived,
        paymentMethod,
        setPaymentMethod,
        paymentMethodsList,
        deliveryConfigsData,
        resetPosValues,
        applyPosValues,
        tax,
        isDigitalPayment,
        setTax,
        posConfigs,
        flatDiscount,
        setFlatDiscount,
        discountType,
        setDiscountType,
        specialDiscount,
        setSpecialDiscount,
        voucherDiscount,
        partnershipValue,
        setPartnershipValue,
        setVoucherDiscount,
        flatDiscountPercentage,
        voucherDetails,
        setVoucherDetails,
        checkoutDialog,
        setCheckoutDialog,
        voucherCode,
        setVoucherCode,
        fbrCharges,
        fbrInvoiceNumber,
        setFbrInvoiceNumber,
        isShiftStarted,
        setIsShiftStarted,
        categoryDiscount,
        setCategoryDiscount,
        FBRPosId,
        deliveryChoice,
        setDeliveryChoice,
        isDeliveryLater,
        setIsDeliveryLater,
        deliveryLaterTiming,
        setDeliveryLaterTiming,
        getIsDeliveryLaterValid,
        delveryHours,
        setDeliveryHours,
        deliveryMinutes,
        setDeliveryMinutes,
        deliveryMeridiem,
        setDeliveryMeridiem,
        deliveryDate,
        setDeliveryDate,
        fbrDialog,
        setFbrDialog,
        fbrIdsList,
        setFbrIdsList,
        setFBRPosId,
        saveProductLotTax,
        merchantIntegrations,
        setMerchantIntegrations,
        setMerchantBinDiscounts,
        merchantBinDiscounts,
        setBinDiscount,
        binDiscount,
        binNumber,
        setBinNumber,
        binBvid,
        setBinBvid,
        showDraftedOrders,
        setShowDraftedOrders,
        orderIdentifier,
        setOrderIdentifier,
        isDraftOrderLoaded,
        setIsDraftOrderLoaded,
        shiftBvid,
        setShiftBvid,
        categoryVoucherId,
        setCategoryVoucherId,
        isHoldOrder,
        setIsHoldOrder,
        tip,
        setTip,
        taxClasses,
        setTaxClasses,
        inclTax,
        setInclTax,
        selectedServer,
        setSelectedServer
      }}
    >
      {children}
    </PosProvider>
  )
}

export default PosContainer
