import React, { useContext, useEffect, useState } from "react"
import { gql, useLazyQuery } from "graphql/apollo"
import { ConfigProvider } from "main/context/config/ConfigContext"
import pulseClient from "graphql/pulse"
import { getFromStorage } from "main/util/LocalStorageHelper"
import UserContext from "main/context/user/UserContext"
import { userTypesList } from "main/util/UserHelper"
import { UserContextType } from "main/context/user/UserContextTypes"
import { updateFavicon } from "main/util/storeHelper"
import RouteHelpers from "utils/RouteHelpers"

const ConfigContainer = ({ children }: { children: React.ReactNode }) => {
  const { isUserRole } = useContext(UserContext) as UserContextType
  const isMasterAdmin = isUserRole(userTypesList.MasterAdmin)
  const [storeMode, setStoreMode] = useState("Default")
  const [merchantBvid, setMerchantBvid] = useState("")
  const [logoImageUrl, setLogoImageUrl] = useState(null)
  const [storeImageUrl, setStoreImageUrl] = useState(null)
  const [masterLogo, setMasterLogo] = useState(null)
  const [currency, setCurrency] = useState("Rs.")
  const [merchantIntegrations, setMerchantIntegrations] = useState([])
  const [isProductLotOn, setIsProductLotOn] = useState(false)
  const [isSyngentaStore, setIsSyngentaStore] = useState(false)

  const RestaurantMode = "Restaurant"

  const performAfterConfig = (data: any) => {
    const { bvid, storeType, config, country, logo } = data
    setIsSyngentaStore(bvid === "6c17ad53-1c90-4cc4-bd76-1ed9d68c249d")
    setMerchantBvid(bvid)
    if (storeType?.storeMode) setStoreMode(storeType.storeMode)
    if (config) {
      const { logoImageUrl, storeImageUrl, isProductLotManagementAllowed } =
        config
      if (logoImageUrl) setLogoImageUrl(logoImageUrl)
      if (storeImageUrl) setStoreImageUrl(storeImageUrl)
      if (isProductLotManagementAllowed)
        setIsProductLotOn(isProductLotManagementAllowed)
    }
    if (country) {
      setCurrency(country?.currency ?? "Rs.")
    }
    if (logo) {
      setMasterLogo(logo)
    }
  }

  const [getMerchantInfoFromSlug] = useLazyQuery(
    GET_MERCHANT_OR_MASTER_BY_SLUG,
    {
      client: pulseClient,
      onCompleted: (data) => {
        const { master, merchant } = data?.registerBySlug || {}
        performAfterConfig(isMasterAdmin ? master : merchant)
      }
    }
  )

  useEffect(() => {
    const checkUser = getFromStorage("user")
    const { slug, tld } =
      checkUser.user.userMerchant || checkUser.user.userMaster || {}

    if (checkUser) {
      getMerchantInfoFromSlug({
        variables: { slug: slug || "", tld: tld || "" }
      })
    }
  }, [])
  useEffect(() => {
    const partner = RouteHelpers.getWhiteLabelPartner()
    if (partner) {
      updateFavicon(partner.favIcon)
      document.title = `${partner.title} POS`
    }
  }, [])

  const isRestaurantMode = (): boolean => {
    return storeMode === RestaurantMode
  }

  return (
    <ConfigProvider
      value={{
        merchantBvid,
        storeMode,
        setStoreMode,
        isRestaurantMode,
        logoImageUrl,
        storeImageUrl,
        setLogoImageUrl,
        setStoreImageUrl,
        masterLogo,
        setMasterLogo,
        currency,
        setCurrency,
        merchantIntegrations,
        setMerchantIntegrations,
        isProductLotOn,
        isSyngentaStore
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default ConfigContainer

const GET_MERCHANT_OR_MASTER_BY_SLUG = gql`
  query registerBySlug($slug: String!, $tld: String) {
    registerBySlug(slug: $slug, tld: $tld) {
      master {
        bvid
        logo
        status
        config {
          isProductLotManagementAllowed
        }
      }
      merchant {
        bvid
        storeName
        status
        slug
        tld
        storeType {
          bvid
          title
          subtitle
          storeMode
        }
        config {
          primaryColor
          logoImageUrl
          storeImageUrl
          preparationTime
          isProductLotManagementAllowed
        }
        country {
          bvid
          currency
        }
      }
    }
  }
`
