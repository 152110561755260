import { MERI_PHARMACY_BVID } from "constant/ProductConstant"

export type DisplayTypes = {
  CashOnDelivery: string
  Safepay: string
  Foree: string
  Prepaid: string
  PayFast: string
  PayPro: string
  BykeaCash: string
  Stripe: string
  Raast: string
  SwipeCard: string
  PickUp: string
  Credit: string
  ChikooPay: string
  Cash: string
}

export const DisplayPaymentTypes: DisplayTypes = Object.freeze({
  CashOnDelivery: "Cash On Delivery",
  Safepay: "Safepay",
  Foree: "Debit/Credit Card",
  Prepaid: "Prepaid",
  PayFast: "PayFast",
  PayPro: "PayPro",
  BykeaCash: "BykeaCash",
  Stripe: "Stripe",
  Raast: "Raast",
  SwipeCard: "Swipe Card",
  PickUp: "Cash On Pickup",
  Credit: "Credit",
  ChikooPay: "Online Payment",
  Cash: "Cash",
  ZindigiQR: "Zindigi QR"
})

type PaymentType = "CashOnDelivery" | "Prepaid" | "SwipeCard" | "Credit"

export const PaymentStatusType: Record<PaymentType, string> = Object.freeze({
  CashOnDelivery: "CashOnDelivery",
  Prepaid: "Prepaid",
  SwipeCard: "SwipeCard",
  Credit: "Credit"
})

const defaultSameKeys = {
  deliveryCharges: "Delivery Charges",
  discountValue: "Discounts",
  fbrCharges: "FBR POS Fee",
  taxAmount: "Tax Collected",
  loyaltyRedemption: "Loyalty Redemption",
  tip: "Tip Collected"
}

export type ShiftUserTypes = {
  totalSalesSummary: {
    [key: string]: string
  }
  alreadyPaidSaleSummary: {
    [key: string]: string
  }
  cardSaleSummary: {
    [key: string]: string
  }
  cashSaleSummary: {
    [key: string]: string
  }
  creditSaleSummary: {
    [key: string]: string
  }
  onlinePaymentSaleSummary: {
    [key: string]: string
  }
  returnSummary: {
    [key: string]: string
  }
}

export const shiftUserReportValues: ShiftUserTypes = Object.freeze({
  totalSalesSummary: {
    ...defaultSameKeys,
    totalOrders: "Total Orders",
    totalSalesValue: "Total Sales Value"
  },
  alreadyPaidSaleSummary: {
    ...defaultSameKeys,
    totalOrders: "Total Orders",
    totalSalesValue: "Total Sales Value"
  },
  cardSaleSummary: {
    ...defaultSameKeys,
    totalOrders: "Total orders paid by card",
    totalSalesValue: "Total card sales value"
  },
  cashSaleSummary: {
    ...defaultSameKeys,
    totalOrders: "Total orders paid in cash",
    totalSalesValue: "Total cash sales value"
  },
  creditSaleSummary: {
    ...defaultSameKeys,
    totalOrders: "Total orders on credit",
    totalSalesValue: "Total credit sales value"
  },
  onlinePaymentSaleSummary: {
    ...defaultSameKeys,
    totalOrders: "Total Online Orders",
    totalSalesValue: "Total Online Sales Value"
  },
  returnSummary: {
    totalRefundValue: "Total Returned Value",
    totalOrders: "Total orders returned",
    cashReturnValue: "Returns in Cash",
    digialReturnValue: "Returns on Card"
  }
})

export const webLinks = (websiteUrl: string, bvid: string) =>
  ({
    [bvid]: websiteUrl,
    [MERI_PHARMACY_BVID]: "https://meripharmacy.pk"
  })[bvid]
