import React, { useContext } from "react"
import { Grid, Theme, Button } from "@mui/material"
import { useTheme } from "@mui/styles"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import OrderSource from "main/pos/order/details/common/OrderSource"
import CustomerFields from "main/pos/order/details/common/CustomerFields"
import DetailsSummary from "main/pos/order/details/common/details_summary/DetailsSummary"
import SummaryButtons from "main/pos/order/details/common/details_summary/SummaryButtons"
import AddressList from "main/pos/order/details/AddressList"
import NewAddress from "main/common/new_address/NewAddress"
import AddIcon from "@mui/icons-material/Add"
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import DeliveryChoices from "./DeliveryChoices"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { OrderTypes } from "main/pos/constant/PosConstant"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"

const styles = (theme: Theme) => ({
  newAddress: {
    marginTop: "8px",
    cursor: "pointer",
    display: "flex",
    textAlign: "center"
  },
  errorColor: {
    color: theme.custom.red
  }
})

const DeliveryDetails = () => {
  const classes = styles(useTheme())
  const configContext = useContext(ConfigContext) as ConfigContextTypes
  const posContext = useContext(PosContext) as PosContextType
  const posCustomerContext = useContext(PosCustomerContext) as PosCustomerType
  const productContext = useContext(PosProductContext) as PosProductType

  const { fulfillments } = posContext?.merchantProfileData?.config ?? {}

  const displaySummaryButton = () => {
    return (
      showNewAddress ||
      posCustomerContext.showSummary ||
      productContext.isDigitalProductInCart()
    )
  }

  const handleNewAddress = () => {
    posCustomerContext.setShowNewAddress(true)
  }

  const handleBackButton = () => {
    posCustomerContext.setShowNewAddress(false)
  }

  const showNewAddress =
    posCustomerContext.showNewAddress || !posCustomerContext.customerAddressList

  return (
    <Grid container>
      <Grid item xs={12}>
        {posCustomerContext.showSummary ? (
          <DetailsSummary />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderSource />
            </Grid>
            <Grid item xs={12}>
              <CustomerFields
                hideAltNumber={posContext.orderType === OrderTypes.WalkIn}
              />
            </Grid>
            {fulfillments?.includes("LaterDelivery") &&
            !configContext.isSyngentaStore ? (
              <Grid item xs={12}>
                <DeliveryChoices />
              </Grid>
            ) : (
              <></>
            )}

            {showNewAddress ? (
              <Grid item xs={12}>
                <NewAddress
                  selectedCity={posCustomerContext.customerCity}
                  setSelectedCity={posCustomerContext.setCustomerCity}
                  cityError={posCustomerContext.customerCityErr}
                  setCityError={posCustomerContext.setCustomerCityErr}
                  selectedArea={posCustomerContext.customerArea}
                  setSelectedArea={posCustomerContext.setCustomerArea}
                  areaError={posCustomerContext.customerAreaErr}
                  setAreaError={posCustomerContext.setCustomerAreaErr}
                  addressLabel="Address"
                  addressPlaceHolder="Address"
                  addressDetails={posCustomerContext.customerAddressDetails}
                  setAddressDetails={
                    posCustomerContext.setCustomerAddressDetails
                  }
                  addressDetailsError={
                    posCustomerContext.customerAddressDetailsErr
                  }
                  setAddressDetailsError={
                    posCustomerContext.setCustomerAddressDetailsErr
                  }
                />
              </Grid>
            ) : posCustomerContext?.customerAddressList?.length > 0 ? (
              <Grid item xs={12}>
                <AddressList />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              {showNewAddress ? (
                <Button
                  color="secondary"
                  sx={classes.newAddress}
                  onClick={handleBackButton}
                  startIcon={<ArrowLeftIcon />}
                >
                  Back
                </Button>
              ) : (
                <Button
                  color="secondary"
                  sx={classes.newAddress}
                  onClick={handleNewAddress}
                  startIcon={<AddIcon />}
                >
                  New Address
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {displaySummaryButton() ? <SummaryButtons /> : <></>}
    </Grid>
  )
}

export default DeliveryDetails
