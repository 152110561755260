import React, { useContext, useEffect, useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid
} from "@mui/material"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import ConnectionHooks from "main/context/connection/ConnectionHook"
import UserContext from "./context/user/UserContext"
import { userTypesList } from "./util/UserHelper"
import { UserContextType } from "./context/user/UserContextTypes"

const styles = () => ({
  selectContainer: {
    padding: "4px 0px 8px 0px"
  }
})
const ServerDropDown = () => {
  const classes = styles()
  const { isUserRole } = useContext(UserContext) as UserContextType
  const isMasterAdmin = isUserRole(userTypesList.MasterAdmin)
  const isMasterPos = isUserRole(userTypesList.MasterPOS)

  const { isInternetOn, firstSync } = ConnectionHooks()
  const [serverlist, setServerList] = useState<any[]>([])
  const { selectedServer, setSelectedServer } = useContext(
    PosContext
  ) as PosContextType

  const fetchOfflinePartnership = async () => {
    const serverList = await fetchDataWithSearch("serverList")
    setServerList(serverList)
  }

  const handleServer = (e: SelectChangeEvent<string>) => {
    setSelectedServer(e.target.value)
  }
  useEffect(() => {
    fetchOfflinePartnership()
  }, [firstSync, isInternetOn])

  return (
    <>
      {serverlist.length && !isMasterAdmin && !isMasterPos ? (
        <Grid container item xs={12} paddingX={2} paddingY={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Server</InputLabel>
              <Select
                label={"Select Server"}
                variant="outlined"
                size="small"
                value={selectedServer}
                sx={classes.selectContainer}
                onChange={handleServer}
              >
                {serverlist?.map((option: any, index: number) => (
                  <MenuItem key={index} value={option?.bvid}>
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}

export default ServerDropDown
