import React from "react"
import { useContext, useRef, useState } from "react"
import { useMutation, useLazyQuery } from "graphql/apollo"
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import ListElement from "main/common/ListElement"
import Counters from "main/pos/common/counters/Counter"
import PaymentMethods from "../payment/PaymentMethods"
import { extractError } from "main/util/ErrorHelper"
import { OrderTypes, PaymentMethodTypes } from "main/pos/constant/PosConstant"
import {
  createOrderVariables,
  createOrderVariablesOffline
} from "../helpers/OrderApiVariables"
import Validator from "main/util/Validator"
import CustomerReceipt from "main/pos/receipt/CustomerReceipt"
import { useReactToPrint } from "react-to-print"
import KitchenReceipt from "main/pos/receipt/KitchenReceipt"
import { useCustomerChange } from "main/pos/hooks/CheckoutHooks"
import { allowOnlyNumbers } from "main/util/NumberHelper"
import CustomerFields from "main/pos/order/details/common/CustomerFields"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { useCustomerValidations } from "main/pos/hooks/PosHooks"
import {
  addBulkDataWithSearch,
  deleteSingleData
} from "offline_database/queries/DbOperations"
import createOrderNumber from "../helpers/OrderNumberGenerator"
import { useNavigate } from "react-router-dom"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { convertDateTimeToString } from "main/util/DateTimeHelper"
import CommonSnackbar from "main/common/common_snackbar/CommonSnackbar"
import pulseClient from "graphql/pulse"
import { saveShiftData } from "../helpers/ShiftHelper"
import {
  CREATE_ORDER,
  GET_ORDER,
  UPDATE_ORDER
} from "main/pos/checkout/CheckoutApi"
import { userTypesList } from "main/util/UserHelper"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { postDataToFBR, getActiveRevenueIntegration } from "utils/fbrHelpers"
import { saveProductsForSync } from "../product/product_lots/helpers/ProductLotsHelper"
import BinDiscountFields from "../order/details/common/BinDiscountFields"
import DatabaseTables from "constant/DatabaseTables"
import { usePosContext } from "main/hooks/ContextHooks"
import payWithCard from "utils/PayCardSwiperHelper"
import PartnershipDropdown from "main/common/PartnershipDropdown"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  customerFields: {
    margin: "24px 0px"
  },
  buttonsContainer: {
    position: "absolute",
    padding: "0 20px",
    bottom: 12,
    right: 0,
    left: 12
  },
  snackBar: {
    cursor: "pointer"
  },
  selectContainer: {
    padding: "4px 0px 12px 0px"
  },
  cashField: {
    marginTop: "12px"
  }
})
type PaymentProvider = {
  paymentProviderStatus: string
  paymentProviderType: string
  [key: string]: any
}

const CheckoutCounter = ({
  setCheckoutDialog
}: {
  setCheckoutDialog: React.Dispatch<React.SetStateAction<any>>
}) => {
  const classes = styles()

  const { isInternetOn } = ConnectionHooks()
  const { isUserRole } = useContext(UserContext) as UserContextType
  const configContext = useContext(ConfigContext) as ConfigContextTypes
  const { isRestaurantMode, storeMode, isProductLotOn } = configContext
  const posContext = useContext(PosContext) as PosContextType
  const productContext = useContext(PosProductContext) as PosProductType
  const customerContext = useContext(PosCustomerContext) as PosCustomerType

  const checkValidations = useCustomerValidations()

  const customerChange = useCustomerChange()

  const [snackBarVariant, setSnackBarVariant] = useState("success")
  const [errorMessage, setErrorMessage] = useState("")
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [cashError, setCashError] = useState("")

  const [paymentMethodError, setPaymentMethodError] = useState(false)
  const [createOrderBvid, setCreateOrderBvid] = useState("")
  const [offlineOrderLoading, setOfflineOrderLoading] = useState(false)
  const [printReceiptLoading, setPrintReceiptLoading] = useState(false)
  const [offlineOrderVariables, setOfflineOrderVariables] = useState<any>({})
  const [isPayButtonDisabled, setPayButtonDisabled] = useState(false)
  const navigate = useNavigate()
  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const { isItMeriPharmacy } = useContext(UserContext) as UserContextType
  const merchantBvid = getBranchBvid()
  const merchantData = posContext.merchantProfileData
  const masterData = posContext.masterProfileData?.master
  const merchantIntegrations = posContext.merchantIntegrations
  const { orderIdentifier } = usePosContext()

  const showCounter =
    posContext.paymentMethod === PaymentMethodTypes.Cash &&
    posContext.orderType !== OrderTypes.PickUp

  const showOrderButtons =
    posContext.paymentMethod !== PaymentMethodTypes.Cash ||
    posContext.orderType === OrderTypes.PickUp

  const isDeliveryOrPickup =
    posContext.orderType === OrderTypes.Delivery ||
    posContext.orderType === OrderTypes.PickUp
  const customerReceiptRef = useRef<any>()
  const kitchenReceiptRef = useRef<any>()
  const handleCustomerReceipt = useReactToPrint({
    content: () => customerReceiptRef.current
  })
  const handleKitchenReceipt = useReactToPrint({
    content: () => kitchenReceiptRef.current
  })
  function handlePromise(func: () => void) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(func())
      }, 2000)
    })
  }
  function printInvoiceOnly() {
    handlePromise(handleCustomerReceipt).then(() => {
      handlePromise(createOrderEffects)
    })
  }
  function printReceipt() {
    if (isRestaurantMode()) {
      handlePromise(handleKitchenReceipt).then(() => {
        handlePromise(handleCustomerReceipt).then(() => {
          handlePromise(createOrderEffects)
        })
      })
    } else {
      handlePromise(handleCustomerReceipt).then(() => {
        handlePromise(createOrderEffects)
      })
    }
  }
  const [getOrderNumber, { data: orderData }] = useLazyQuery(GET_ORDER, {
    client: pulseClient
  })

  const [createOrder, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER,
    {
      client: pulseClient,
      variables: {
        ...(merchantBvid && { merchantBvid: merchantBvid })
      },
      onCompleted: (data) => {
        if (data) setCreateOrderBvid(data?.createCustomOrder)
      }
    }
  )

  const [updateFBRInvoiceNo, { loading: updateInvoiceLoading }] = useMutation(
    UPDATE_ORDER,
    {
      client: pulseClient
    }
  )

  const handleCashReceived = (e: React.ChangeEvent<HTMLInputElement>) => {
    posContext.setCashReceived(allowOnlyNumbers(e.target.value))
  }

  function createOrderEffects() {
    setSnackBarVariant("success")
    setShowSnackBar(true)
    setErrorMessage("Order has been created")
    createShiftDetails()
    saveProductsForSync(
      productContext?.selectedProductsList.filter(
        (item: any) => !Object.hasOwn(item, "extraItem")
      )
    )
    setTimeout(() => {
      resetPosStates()
    }, 2000)
  }
  const createShiftDetails = () => {
    if (isUserRole(userTypesList.ShiftUser)) {
      const paymentsList = [
        PaymentMethodTypes.Cash,
        PaymentMethodTypes.CashOnDelivery
      ]
      const checkPayment = paymentsList.includes(posContext.paymentMethod)
      const total = productContext.calculateProductsTotal()
      if (total) saveShiftData("totalSales", total)
      if (checkPayment && total) saveShiftData("cashSales", total)
    }
  }
  const isOrderLoading =
    createOrderLoading ||
    offlineOrderLoading ||
    printReceiptLoading ||
    updateInvoiceLoading

  const activeRevenueIntegration =
    getActiveRevenueIntegration(merchantIntegrations)?.partner

  async function saveOrder(withPrinting?: boolean) {
    if (isPayButtonDisabled) return
    setPayButtonDisabled(true)

    const isValidated = showCounter
      ? checkCashValidation() &&
        checkPaymentMethodValidation() &&
        checkValidations()
      : checkPaymentMethodValidation() && checkValidations()

    if (isValidated) {
      try {
        const swipeCardResponse = await handlePayWithCard()
        if (!swipeCardResponse) return
        clearCheckoutErrors()
        const orderVariables = createOrderVariables(
          posContext,
          productContext,
          customerContext,
          storeMode
        )

        if (isInternetOn) {
          const response = await createOrder(orderVariables as object)
          const orderResult = await getOrderNumber({
            variables: { bvid: response.data.createPosOrder }
          })
          const orderNumber = orderResult.data.order.orderNumber

          if (posContext.FBRPosId) {
            let fbrInvoiceNumber
            const fbrResponse = (await postDataToFBR(
              {
                isProductLotOn,
                isItMeriPharmacy,
                productContext,
                posContext,
                configContext,
                orderNumber,
                orderData: orderResult.data.order,
                customerContext
              },
              activeRevenueIntegration
            )) as any
            if (fbrResponse?.InvoiceNumber) {
              fbrInvoiceNumber = fbrResponse.InvoiceNumber
              posContext.setFbrInvoiceNumber(fbrResponse.InvoiceNumber)
            } else if (fbrResponse?.srbInvoceId) {
              fbrInvoiceNumber = fbrResponse.srbInvoceId
              posContext.setFbrInvoiceNumber(fbrResponse.srbInvoceId)
            }
            if (fbrInvoiceNumber)
              await updateFBRInvoiceNo({
                variables: {
                  bvid: response?.data?.createPosOrder,
                  fbrInvoiceNo: fbrInvoiceNumber,
                  fbrPosCharges: 0
                }
              })
          }

          if (withPrinting) {
            setPrintReceiptLoading(true)

            if (orderResult?.data) {
              if (orderIdentifier?.length) {
                printInvoiceOnly()
              } else {
                printReceipt()
              }
            }
          } else createOrderEffects()
        } else {
          createOfflineOrder(orderVariables)
          if (withPrinting) {
            setPrintReceiptLoading(true)
            if (orderIdentifier?.length) {
              printInvoiceOnly()
            } else {
              printReceipt()
            }
          } else createOrderEffects()
        }
        if (orderIdentifier?.length)
          await deleteSingleData(
            DatabaseTables.draftOrderTable,
            "orderNumber",
            orderIdentifier
          )
      } catch (error) {
        setSnackBarVariant("error")
        setShowSnackBar(true)
        setErrorMessage(extractError(error))
        setPayButtonDisabled(false)
      }
    } else {
      setPayButtonDisabled(false)
    }
  }

  function checkCashValidation() {
    if (posContext.paymentMethod === PaymentMethodTypes.Cash) {
      const cashCompare =
        posContext.cashReceived >= productContext.calculateProductsTotal()
      const cashEmpty = Validator.isEmpty(posContext.cashReceived)
      const cashValidation = cashEmpty || !cashCompare

      if (cashEmpty) {
        setCashError("Field cannot be empty")
      } else if (!cashCompare)
        setCashError("Price cannot be less than total price")

      return !cashValidation
    }
    return true
  }

  function checkPaymentMethodValidation() {
    const paymentValidation = Validator.isEmpty(posContext.paymentMethod)
    setPaymentMethodError(paymentValidation)
    return !paymentValidation
  }
  const handlePayWithCard = async (): Promise<boolean> => {
    const total = productContext.calculateProductsTotal()
    const { paymentMethod, orderType } = posContext
    const isPayableByCard =
      paymentMethod === PaymentMethodTypes.SwipeCardWithMachine &&
      orderType === OrderTypes.WalkIn
    if (isPayableByCard) {
      const paymentResponse: any = await payWithCard(total)
      const isPaymentSuccess =
        !paymentResponse ||
        (paymentResponse as string).replace(/[\r\n]/gm, "") === "Cancelled"
      if (isPaymentSuccess) {
        setShowSnackBar(true)
        setSnackBarVariant("error")
        setErrorMessage("Try again, Swipe card payment is not working.")
        setPayButtonDisabled(false)
        return false
      }
    }
    return true
  }

  function clearCheckoutErrors() {
    setCashError("")
    setPaymentMethodError(false)
  }

  function closeSnackbar() {
    setShowSnackBar(false)
    setErrorMessage("")
  }
  function getOrderDetails() {
    navigate(`/orders/${createOrderBvid}`)
  }
  const createOfflineOrder = async (orderVariables: any) => {
    setOfflineOrderLoading(true)
    orderVariables.variables.orderNumber = await createOrderNumber()
    orderVariables.variables.orderTime = convertDateTimeToString()
    const orderOfflineVariables = await createOrderVariablesOffline(
      orderVariables,
      posContext,
      productContext,
      customerContext
    )
    await addBulkDataWithSearch(
      "orderList",
      [orderOfflineVariables?.variables],
      ["orderNumber"]
    )
    setOfflineOrderVariables({ order: orderOfflineVariables?.variables })
  }

  function resetPosStates() {
    posContext.resetPosValues()
    customerContext.resetPosCustomerValues()
    productContext.resetPosProductValues()
    setOfflineOrderLoading(false)
    setPrintReceiptLoading(false)
    setCheckoutDialog(false)
  }

  const isChikooPaymentActive = (
    paymentProviders: PaymentProvider[]
  ): boolean => {
    return paymentProviders.some(
      (provider: PaymentProvider) =>
        provider.paymentProviderType === "ChikooPay" &&
        provider.paymentProviderStatus === "Active"
    )
  }
  const dataForPrint = isInternetOn ? orderData : offlineOrderVariables
  const isBinDiscountAvailable =
    posContext.paymentMethod === PaymentMethodTypes.SwipeCard &&
    isInternetOn &&
    isChikooPaymentActive(posContext.paymentMethodsList)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {paymentMethodError ? (
            <Typography color="error" align="left">
              Please select payment method
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <PaymentMethods />
        </Grid>
        <Grid item xs={12}>
          <PartnershipDropdown />
        </Grid>
        {showCounter ? (
          <>
            <Grid item xs={12} sx={classes.cashField}>
              <TextField
                fullWidth
                variant="outlined"
                name="cash_received"
                label="Cash Received"
                placeholder="500"
                id="cash-received-text"
                error={cashError ? true : false}
                helperText={cashError}
                value={posContext.cashReceived}
                onChange={handleCashReceived}
              />
            </Grid>
            <Grid item xs={12}>
              <Counters
                state={posContext.cashReceived}
                handleCounter={posContext.setCashReceived}
              />
            </Grid>
            <Grid item xs={12}>
              <ListElement
                bold
                label="Cash Received"
                id="cash-received"
                value={posContext.cashReceived || 0}
              />
              <ListElement
                bold
                label="Customer Change"
                id="customer-change"
                value={customerChange}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}

        {posContext.paymentMethod === PaymentMethodTypes.OnlinePayment ||
        posContext.paymentMethod === PaymentMethodTypes.Credit ? (
          <Grid item xs={12} sx={classes.customerFields}>
            <CustomerFields disabled={isDeliveryOrPickup} hideAltNumber />
          </Grid>
        ) : (
          <></>
        )}
        {isBinDiscountAvailable ? (
          <Grid item xs={12} sx={classes.customerFields}>
            <BinDiscountFields />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          {isOrderLoading ? (
            <Grid textAlign="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              sx={showOrderButtons ? classes.buttonsContainer : {}}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  size="large"
                  data-id="create-order-btn"
                  disabled={isPayButtonDisabled}
                  onClick={() => saveOrder()}
                >
                  {isDeliveryOrPickup ? "Create Order" : "Pay"}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  data-id="create-order-and-print-receipt-btn"
                  disabled={isPayButtonDisabled}
                  onClick={() => saveOrder(true)}
                >
                  {isDeliveryOrPickup ? "Create Order & Print" : "Pay & Print"}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CommonSnackbar
        action={getOrderDetails}
        variant={snackBarVariant}
        open={showSnackBar}
        message={errorMessage}
        onClose={closeSnackbar}
      />
      {(orderData && merchantData) ||
      (!isInternetOn && Object.keys(offlineOrderVariables)?.length) ? (
        <Grid container style={{ display: "none" }}>
          <CustomerReceipt
            ref={customerReceiptRef}
            merchantData={merchantData}
            data={dataForPrint}
            isFBRIntegrated={Boolean(posContext.FBRPosId)}
            merchantIntegrations={merchantIntegrations}
            masterData={masterData}
          />
        </Grid>
      ) : (
        <></>
      )}
      {(orderData && merchantData) ||
      (!isInternetOn && Object.keys(offlineOrderVariables)?.length) ? (
        <Grid container style={{ display: "none" }}>
          <KitchenReceipt ref={kitchenReceiptRef} data={dataForPrint} />
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}
export default CheckoutCounter
