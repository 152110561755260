import {
  convertProductDetails,
  convertExtraItemDetails
} from "main/pos/helpers/ProductHelpers"
import { DiscountTypes, OrderTypes } from "main/pos/constant/PosConstant"
import { getPaymentType } from "main/util/PaymentHelper"
import { convert03to92 } from "main/util/NumberHelper"
import {
  getCurrentDateTime,
  convertDateTimeToString,
  formatSimpleDateTime
} from "main/util/DateTimeHelper"

export const createOrderVariables = (
  posContext: any,
  productContext: any,
  customerContext: any,
  storeMode: any,
  fbrInvoiceNumber?: any
) => {
  const variables: { orderNumber?: string } = {}

  createMandatoryVariables(
    variables,
    posContext,
    productContext,
    customerContext,
    storeMode,
    fbrInvoiceNumber
  )
  createDeliveryVariables(variables, posContext, customerContext)
  createPickUpVariables(variables, posContext, customerContext)
  createWalkInVariables(variables, posContext, customerContext)
  createDiscountVariables(variables, posContext)

  return { variables }
}

const createMandatoryVariables = (
  variables: any,
  posContext: any,
  productContext: any,
  customerContext: any,
  storeMode: any,
  fbrInvoiceNumber: any
) => {
  variables.comment = posContext.orderInstruction
  variables.orderSource =
    posContext.orderType === OrderTypes.WalkIn
      ? OrderTypes.WalkIn
      : customerContext.orderSource
  variables.products = convertProductDetails(
    productContext.selectedProductsList,
    storeMode
  )

  variables.orderLineItems = convertExtraItemDetails(
    productContext.selectedProductsList
  )
  variables.paymentType = getPaymentType(posContext)

  variables.deliveryCharges =
    posContext.orderType === OrderTypes.Delivery
      ? posContext.deliveryCharges
      : 0

  if (posContext.shiftBvid) variables.shiftBvid = posContext.shiftBvid
  if (posContext.tip) variables.tip = parseFloat(posContext.tip)

  variables.orderIdentifier = posContext.orderIdentifier

  if (fbrInvoiceNumber) variables.fbrInvoiceNo = fbrInvoiceNumber
  if (posContext.fbrCharges) {
    variables.fbrPosCharges = posContext.fbrCharges
    variables.fbrInvoiceNo = posContext.fbrInvoiceNumber
  }
  if (posContext?.selectedServer)
    variables.serverUserBvid = posContext?.selectedServer

  createCustomerDetailsVariables(variables, customerContext)
}

const createDeliveryVariables = (
  variables: any,
  posContext: any,
  customerContext: any
) => {
  if (posContext.orderType === OrderTypes.Delivery) {
    if (posContext.partnershipValue) {
      variables.partnershipBvid = posContext.partnershipValue
    }

    if (posContext?.isDeliveryLater) {
      variables.isLaterDelivery = posContext?.isDeliveryLater
      variables.dispatchDetails = {
        deliveryDate: posContext?.deliveryLaterTiming
      }
    }
    if (customerContext.customerAddressId && !customerContext.showNewAddress) {
      variables.addressUid = customerContext.customerAddressId
    } else {
      variables.deliveryAddress = {
        details: customerContext.customerAddressDetails,
        areaBvid: customerContext.customerArea?.bvid || "",
        coordinates: { longitude: 0.0, latitude: 0.0 }
      }
    }
  }
}

const createPickUpVariables = (
  variables: any,
  posContext: any,
  customerContext: any
) => {
  if (posContext.orderType === OrderTypes.PickUp) {
    if (!posContext.partnershipValue) {
      variables.partnershipBvid = posContext.partnershipValue
    }
    variables.dispatchDetails = {
      dispatchType: "StorePickup",
      expectedAt: convertDateTimeToString(customerContext.orderPickupTime)
    }
  }
}
const createWalkInVariables = (
  variables: any,
  posContext: any,
  customerContext: any
) => {
  if (posContext.orderType === OrderTypes.WalkIn) {
    if (posContext.partnershipValue) {
      variables.partnershipBvid = posContext.partnershipValue
    }

    variables.dispatchDetails = {
      dispatchType: "StorePickup",
      expectedAt: convertDateTimeToString(getCurrentDateTime())
    }
    if (customerContext.customerAddressId && !customerContext.showNewAddress) {
      variables.addressUid = customerContext.customerAddressId
    } else {
      variables.deliveryAddress = {
        details: customerContext.customerAddressDetails,
        areaBvid: customerContext.customerArea?.bvid || "",
        coordinates: { longitude: 0.0, latitude: 0.0 }
      }
    }
  }
}

const createDiscountVariables = (variables: any, posContext: any) => {
  if (posContext.discount || posContext.categoryDiscount)
    variables.discountLine = {
      discountedAmount: parseFloat(
        posContext.discount || posContext.categoryDiscount
      ),
      discountType: getDiscountTypeVariables(
        posContext.voucherDiscount,
        posContext.flatDiscount,
        posContext.specialDiscount,
        posContext.categoryDiscount
      ),
      ...(posContext.voucherDiscount && {
        voucherXid: posContext.voucherDetails?.voucherXid,
        voucherCode: posContext.voucherDetails?.voucherCode
      })
    }
  if (posContext?.binBvid) {
    variables.binBvid = posContext.binBvid
    delete variables.discountLine
  }
  if (posContext.partnershipValue) {
    variables.partnershipBvid = posContext.partnershipValue
  }
}

const createCustomerDetailsVariables = (
  variables: any,
  customerContext: any
) => {
  if (customerContext.customerPhone && customerContext.customerName)
    variables.customerDetails = {
      ...(customerContext.email && { email: customerContext.email }),
      user: {
        name: customerContext.customerName,
        username: convert03to92(customerContext.customerPhone)
      },
      alternatePhoneNumber:
        convert03to92(customerContext?.alternatePhoneNumber) || ""
    }
}

export const createOrderVariablesOffline = (
  orderVariables: any,
  posContext: any,
  productContext: any,
  customerContext: any
) => {
  const { customerAddressDetails, customerArea, customerCity } =
    customerContext || {}
  const temp = {
    orderedProducts: convertProductDetails(
      productContext.selectedProductsList,
      "",
      true,
      true
    ),
    subTotal: productContext.getProductsSubTotal(),
    totalAmount: productContext.calculateProductsTotal(),
    taxAmount: posContext.tax,
    orderIdentifier: posContext.orderIdentifier,
    offlineOrderDate: formatSimpleDateTime(getCurrentDateTime()),
    ...(customerAddressDetails && {
      address: `${customerAddressDetails || ""} ${
        customerArea?.name ? `,${customerArea?.name},` : ""
      } ${customerCity?.name ? `${customerCity?.name},` : ""}`
    })
  }
  orderVariables.variables.temp = temp

  if (posContext?.shiftBvid)
    orderVariables.variables.shiftBvid = posContext.shiftBvid

  if (posContext?.tip) orderVariables.variables.tip = parseFloat(posContext.tip)
  return orderVariables
}

const getDiscountTypeVariables = (
  voucherDiscount: number,
  flatDiscount: number,
  specialDiscount: number,
  categoryDiscount: number
) => {
  if (voucherDiscount) {
    return DiscountTypes.Voucher
  } else if (specialDiscount) {
    return DiscountTypes.Invoice
  } else if (flatDiscount) {
    return DiscountTypes.Flat
  } else if (categoryDiscount) {
    return DiscountTypes.Category
  }
}
