import React, { useCallback, useContext, useEffect, useState } from "react"
import { Grid } from "@mui/material"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import CategoryAccordion from "main/pos/product/CategoryAccordion"
import Search from "main/common/Search"
import ProductsSection from "main/pos/product/product_list/ProductsSection"
import {
  fetchDataWithSearch,
  fetchDataWithSearchPaginated,
  fetchMultiFieldSearchPaginated
} from "offline_database/queries/DbOperations"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import Empty from "main/common/Empty"
import debounceHelper from "main/util/DebounceHelper"
import { getCategoryDiscount } from "main/util/ProductHelper"
import ConnectionHooks from "main/context/connection/ConnectionHook"
import BarcodeScanner from "main/scanner/BarcodeScanner"
import { setProductSync } from "main/context/connection/ConnectionSlice"
import { useDispatch } from "react-redux"

const styles = () => ({
  productContainer: {
    marginTop: 5
  }
})

const Products = () => {
  const classes = styles()
  const productContext = useContext(PosProductContext) as PosProductType
  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const { isInternetOn, firstSync, productSync } = ConnectionHooks()
  const [offlineProducts, setOfflineProducts] = useState<any>([])
  const branchBvid = getBranchBvid()
  const dispatch = useDispatch()

  const fetchOfflineProducts = async (
    fetchData: (
      _tableName: string,
      _query?: any,
      _search?: any,
      _limit?: number
    ) => any,
    tableName: string,
    query?: any,
    search?: any,
    limit?: number
  ) => {
    const searchValue = checkSearchForTilde(search)
    let productList: any
    if (query?.length === 2 && typeof query !== "string") {
      productList = await fetchMultiFieldSearchPaginated(
        "productList",
        query[0],
        query[1],
        searchValue,
        50
      )
    } else {
      productList = await fetchData(tableName, query, searchValue, limit)
    }
    const categoryList = await fetchDataWithSearch("categoryList")
    if (productList?.length) {
      const productWithDiscount: any = productList.map((product: any) => {
        const categoryDiscount = getCategoryDiscount(product, categoryList)
        return {
          ...product,
          ...categoryDiscount
        }
      })
      setOfflineProducts(productWithDiscount)
    }
  }

  const checkSearchForTilde = (searchValue: string) => {
    const isTildeThere = searchValue?.search("~")
    if (isTildeThere) {
      return searchValue?.split("~")[0]
    }
    return searchValue
  }

  const searchProducts = useCallback(
    debounceHelper(
      (
        fetchData: () => void,
        tableName: string,
        query?: string,
        search?: any,
        limit?: number
      ) => {
        fetchOfflineProducts(fetchData, tableName, query, search, limit)
      },
      500
    ),
    []
  )

  useEffect(() => {
    fetchOfflineProducts(
      fetchDataWithSearchPaginated,
      "productList",
      "categoryBvid",
      "",
      50
    )
  }, [branchBvid, isInternetOn, firstSync])

  useEffect(() => {
    if (productSync)
      fetchOfflineProducts(
        fetchDataWithSearchPaginated,
        "productList",
        "categoryBvid",
        "",
        50
      )
    dispatch(setProductSync(false))
  }, [productSync])

  const handleSearch = (searchTerm: string | number): void => {
    productContext.setProductSearch(searchTerm)
    searchProducts(
      fetchDataWithSearchPaginated,
      "productList",
      ["name", "description"],
      searchTerm,
      50
    )
  }

  const handleEnter = (searchValue: string) => {
    fetchOfflineProducts(
      fetchDataWithSearchPaginated,
      "productList",
      ["name", "description"],
      searchValue,
      50
    )
  }

  return (
    <Grid container sx={classes.productContainer} spacing={1}>
      <Grid item xs={9.5}>
        <Search
          label="Search"
          placeholder="Start typing product details"
          searchVariant="outlined"
          searchValue={productContext.productSearch}
          setSearchValue={handleSearch}
          searchInputRef={productContext.productSearchRef}
          onEnter={handleEnter}
        />
      </Grid>
      <Grid item xs={2.5}>
        <BarcodeScanner searchProducts={searchProducts} />
      </Grid>
      <Grid item xs={12}>
        <CategoryAccordion fetchOfflineProducts={fetchOfflineProducts} />
      </Grid>

      <Grid item xs={12}>
        {offlineProducts?.length ? (
          <ProductsSection
            offlineProductData={offlineProducts}
            fetchOfflineProducts={fetchOfflineProducts}
          />
        ) : (
          <Empty />
        )}
      </Grid>
    </Grid>
  )
}

export default Products
