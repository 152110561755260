import { gql } from "@apollo/client"

export const BIN_DISCOUNTS_BY_MERCHANT = gql`
  query binDiscountsForMerchantXid($merchantXid: String) {
    binDiscountsForMerchantXid(merchantXid: $merchantXid) {
      bvid
      merchantXid
      binBvid
      binNo
      discount
      maxDiscount
      minOrder
      isActive
      startDate
      endDate
      days
      bankBvid
    }
  }
`
export const GET_PARTNERSHIP = gql`
  query Partnerships {
    partnerships {
      bvid
      partnershipName
      paymentTypes
    }
  }
`
export const GET_MERCHANT_CUSTOMERS = gql`
  query getMerchantCustomersWithAddress(
    $merchantBvid: String
    $size: Int!
    $offset: Int!
  ) {
    getMerchantCustomersWithAddress(
      merchantBvid: $merchantBvid
      size: $size
      offset: $offset
    ) {
      count
      customers {
        username
        firstName
        lastName
        addresses {
          uid
          details
          isPrimary
          location {
            coordinates
          }
          area {
            name
            bvid
            city {
              name
              bvid
            }
          }
        }
      }
    }
  }
`
export const GET_CATEGORIES = gql`
  query merchantCategories($status: CategoryStatus, $merchantXid: ID) {
    merchantCategories(status: $status, merchantXid: $merchantXid) {
      uid
      isActive
      name
      bvid
      imgUrl
      totalProducts
      flatDiscount
      flatDiscountApp
      merchantSubcategories {
        uid
        name
        bvid
      }
    }
  }
`

export const PRODUCTS = gql`
  query merchantProductsForPos(
    $searchKeywords: String
    $offset: Int
    $size: Int
    $productStatus: ProductStatus
    $categoryBvid: ID
    $merchantXid: ID
  ) {
    merchantProductsForPos(
      searchKeywords: $searchKeywords
      offset: $offset
      size: $size
      productStatus: $productStatus
      categoryBvid: $categoryBvid
      merchantXid: $merchantXid
    ) {
      products {
        pctCode
        posId
        gtin
        name
        description
        bvid
        barcode
        uid
        productStatus
        productType
        price
        basePrice
        discountedBasePrice
        barcode
        discountedPrice
        quantity
        shortUrl
        categoryBvid
        subcategoryBvid
        cartonSize
        minOrderQuantity
        maxOrderQuantity
        taxClass {
          cashRate
          digitalRate
          isArchived
          isInclusive
          thirdSchedule
        }
        images {
          thumbnailUrl
          isHero
        }
        productIntegrations {
          integrationId
          integrationType
          integrationCode
          price
          discountedPrice
        }
        variantOptions {
          bvid
          title
          isActive
          variantOptionValues {
            bvid
            title
            sortOrder
            isActive
          }
        }
        productLots {
          bvid
          expiry
          lotCode
          quantityInside
          price
          gstValue
          customField1
          customField2
          customField3
          status
        }
        variants {
          bvid
          isActive
          variantPrice
          discountedPrice
          variantOptionValues {
            bvid
            title
            isActive
            sortOrder
          }
        }
        batches {
          batchName
          quantity
          discountedPrice
          price
          batchCode
          expiry
          batchProductBvid
        }
        batchQuantity
        mainOptions {
          bvid
          title
          sortOrder
          isMandatory
          isMultiSelect
          isActive
          quantity
          mainOptionValues {
            bvid
            title
            sortOrder
            optionPrice
            discountedPrice
            isActive
            maxAllowed
            productIntegrations {
              integrationId
              integrationType
              integrationCode
              price
              discountedPrice
            }
            subOptions {
              bvid
              title
              sortOrder
              isMandatory
              isMultiSelect
              isActive
              subOptionValues {
                bvid
                title
                sortOrder
                optionPrice
                discountedPrice
                isActive
                productIntegrations {
                  integrationId
                  integrationType
                  integrationCode
                  price
                  discountedPrice
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
export const GET_CITIES = gql`
  query getCitiesForPos($merchantBvid: String) {
    getCitiesForPos(merchantBvid: $merchantBvid) {
      name
      bvid
    }
  }
`
export const GET_AREAS = gql`
  query getAreasForPos($cityBvid: String, $merchantBvid: String) {
    getAreasForPos(cityBvid: $cityBvid, merchantBvid: $merchantBvid) {
      name
      bvid
      city {
        name
        bvid
      }
    }
  }
`

export const DELIVERY_CONFIG = gql`
  query deliveryConfigs($merchantBvid: ID) {
    deliveryConfigs(merchantBvid: $merchantBvid) {
      deliveryCharges
      selectedCity {
        name
        bvid
      }
      selectedArea {
        name
        bvid
      }
      bvid
    }
  }
`

export const MERCHANT_PAYMENT_PROVIDER = gql`
  query merchantPaymentProviders($merchantXid: String) {
    merchantPaymentProviders(merchantXid: $merchantXid) {
      bvid
      paymentProviderStatus
      paymentProviderType
    }
  }
`
export const GET_MERCHANT_PAYMENTS_MODE = gql`
  query merchantPaymentModes($merchantBvid: String) {
    merchantPaymentModes(merchantBvid: $merchantBvid) {
      mode
      availableOn
    }
  }
`
export const GET_TAX_CLASSES = gql`
  query TaxClasses($merchantXid: String) {
    taxClasses(merchantXid: $merchantXid) {
      taxClasses {
        bvid
        name
        merchantXid
        masterXid
        cashRate
        digitalRate
        vat
        isInclusive
        thirdSchedule
        isArchived
      }
    }
  }
`
export const MERCHANT_PROFILE = gql`
  query merchantProfile($bvid: ID) {
    merchantProfile(bvid: $bvid) {
      storeName
      config {
        deliveryChargesThreshold
        deliveryCharges
        minimumOrderCost
        logoImageUrl

        flatDiscount
        fulfillments
        isProductLotManagementAllowed
        printCustomText
        printLogoGreyScale
        isInclusiveTax
        receiptTitle
      }
      profile {
        ntn
        contacts {
          phones
          address {
            area {
              name
              city {
                name
              }
            }
            details
          }
        }
      }
    }
  }
`

export const GET_MASTER_PROFILE = gql`
  query getMasterProfile($masterBvid: String) {
    getMasterProfile(masterBvid: $masterBvid) {
      master {
        bvid
        slug
        tld
        logo
        bannerImage
        hasDomain
        config {
          printCustomText
          printLogoGreyScale
          receiptTitle
        }
      }
    }
  }
`

export const ALL_AREAS = gql`
  query allAreasForPos($offset: Int!, $size: Int!) {
    allAreasForPos(size: $size, offset: $offset) {
      areas {
        name
        bvid
        city {
          name
          bvid
        }
      }
      count
    }
  }
`

export const GET_POS_CONFIG = gql`
  query posConfig($merchantBvid: ID) {
    posConfig(merchantBvid: $merchantBvid) {
      autoSync
      offlineMode
    }
  }
`

export const GET_MERCHANT_INTEGRATIONS = gql`
  query merchantIntegrations($merchantXid: ID) {
    merchantIntegrations(merchantXid: $merchantXid) {
      bvid
      apiKey
      status
      partner
      xid
    }
  }
`

export const GET_SERVER = gql`
  query ServerUsers($merchantBvid: String!) {
    serverUsers(merchantBvid: $merchantBvid) {
      bvid
      name
      phoneNumber
      isVerified
      merchantBvid
    }
  }
`
